<template>
  <a-form
    ref="formRef"
    :model="formState"
    :rules="rules"
    label-align="left"
    layout="vertical"
    @finishFailed="handleFinishFailed"
  >
    <br />
    <a-form-item name="id" label="ID">
      <a-input v-model:value="formState.id" size="large" />
    </a-form-item>
    <a-tabs v-model:activeKey="activeKey" default-active-key="1" size="large">
      <a-tab-pane key="1" tab="Titre">
        <a-form-item name="titleFr">
          <a-input v-model:value="formState.titleFr" placeholder="Titre" />
        </a-form-item>
      </a-tab-pane>
      <a-tab-pane key="2" tab="عنوان">
        <a-form-item name="titleAr">
          <a-input v-model:value="formState.titleAr" placeholder="عنوان" />
        </a-form-item>
      </a-tab-pane>
      <a-tab-pane key="3" tab="Title">
        <a-form-item name="titleEn">
          <a-input v-model:value="formState.titleEn" placeholder="Title" />
        </a-form-item>
      </a-tab-pane>
    </a-tabs>
    <a-form-item label="Categorie" name="category">
      <a-select v-model:value="formState.category">
        <a-select-option v-for="category of categories" :key="category._id" :value="category._id">
          {{ category.title[language[activeKey ? parseInt(activeKey) - 1 : 0]] }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-tabs v-model:activeKey="activeKey" default-active-key="1" size="large">
      <a-tab-pane key="1" tab="Contenue ">
        <a-form-item name="descFr">
          <quill-editor v-model:value="formState.descFr" style="height: 200px"> </quill-editor>
        </a-form-item>
      </a-tab-pane>
      <a-tab-pane key="2" tab="محتوى">
        <a-form-item name="descAr">
          <quill-editor v-model:value="formState.descAr" style="height: 200px"> </quill-editor>
        </a-form-item>
      </a-tab-pane>
      <a-tab-pane key="3" tab="Content">
        <a-form-item name="descEn">
          <quill-editor v-model:value="formState.descEn" style="height: 200px"> </quill-editor>
        </a-form-item>
      </a-tab-pane>
    </a-tabs>
    <!-- couverture -->
    <a-tabs v-model:activeKey="activeKey" default-active-key="1" size="large">
      <a-tab-pane key="1" tab="Photo de couverture">
        <!-- french -->

        <a-form-item label="Photo de couverture">
          <a-upload-dragger
            :multiple="false"
            :file-list="covImgFileListFr"
            :before-upload="beforeUpload"
            :custom-request="
              () => {
                return true
              }
            "
          >
            <p class="ant-upload-drag-icon">
              <inbox-outlined></inbox-outlined>
            </p>
            <p class="ant-upload-text">
              Cliquez ou faites glisser le fichier vers la zone à télécharger
            </p>
            <p class="ant-upload-hint">
              Prise en charge d'un téléchargement unique ou groupé.
            </p>
          </a-upload-dragger>
        </a-form-item>
      </a-tab-pane>
      <!-- arabic -->

      <a-tab-pane key="2" tab="صورة الغلاف">
        <a-form-item label="صورة الغلاف">
          <a-upload-dragger
            :multiple="false"
            :file-list="covImgFileListAr"
            :before-upload="beforeUpload"
            :custom-request="
              () => {
                return true
              }
            "
          >
            <p class="ant-upload-drag-icon">
              <inbox-outlined></inbox-outlined>
            </p>
            <p class="ant-upload-text">انقر أو اسحب الملف إلى المنطقة لتحميله</p>
            <p class="ant-upload-hint">
              دعم للتحميل الفردي أو المجمع.
            </p>
          </a-upload-dragger>
        </a-form-item>
      </a-tab-pane>
      <!-- english -->

      <a-tab-pane key="3" tab="Cover photo">
        <a-form-item label="Cover photo">
          <a-upload-dragger
            :multiple="false"
            :file-list="covImgFileListEn"
            :before-upload="beforeUpload"
            :custom-request="
              () => {
                return true
              }
            "
          >
            <p class="ant-upload-drag-icon">
              <inbox-outlined></inbox-outlined>
            </p>
            <p class="ant-upload-text">Click or drag file to area to upload</p>
            <p class="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from uploading company data or
              other band files
            </p>
          </a-upload-dragger>
        </a-form-item>
      </a-tab-pane>
    </a-tabs>
    <a-form-item label="Attaché des fichiers">
      <a-upload-dragger
        :file-list="files"
        :before-upload="beforeUploadFiles"
        :custom-request="
          () => {
            return true
          }
        "
      >
        <p class="ant-upload-drag-icon">
          <inbox-outlined></inbox-outlined>
        </p>
        <p class="ant-upload-text">Click or drag file to area to upload</p>
        <p class="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading company data or
          other band files
        </p>
      </a-upload-dragger>
    </a-form-item>
    Envoyer aux abonnés
    <a-switch v-model:checked="sendToSubscribed" checked-children="oui" un-checked-children="non" />
    <br /><br />
    <a-form-item>
      <button class="btn btn-success btn-with-addon text-nowrap" @click="onSubmit">
        <span class="btn-addon">
          <i class="btn-addon-icon fe fe-plus-circle"></i>
        </span>
        Modifer La publication
      </button>
    </a-form-item>
  </a-form>
</template>
<script>
/* eslint-disable */
import { quillEditor } from 'vue3-quill'
import { InboxOutlined } from '@ant-design/icons-vue'
import { useRouter, useRoute } from 'vue-router'
import { defineComponent, toRaw, ref, reactive, toRefs } from 'vue'
import { message } from 'ant-design-vue'

import ApiClient from '@/services/axios'

export default defineComponent({
  components: {
    InboxOutlined,
    quillEditor,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    let categories = ref([])
    let _id = route.params.id
    const formState = reactive({
      titleFr: '',
      category: '',
      titleAr: '',
      titleEn: '',
      descFr: '',
      descAr: '',
      descEn: '',
      id: '',
    })
    ApiClient.post('/publications_cetegories/filter', {
      query: { status: 'active' },
    }).then(res => {
      categories.value = [...res.data]
    })
    ApiClient.post('/publications/filter', {
      query: {
        _id: _id,
      },
      aggregation: [
        {
          $lookup: {
            from: 'publicationcategories',
            localField: 'category',
            foreignField: '_id',
            as: 'category',
          },
        },
        {
          $project: {
            _id: 1,
            category: { $arrayElemAt: ['$category', 0] },
            title: 1,
            converImg: 1,
            status: 1,
            seen: 1,
            desc: 1,
            files: 1,
            date: 1,
            createdAt: 1,
            id: 1,
          },
        },
      ],
    })
      .then(res => {
        const data = res.data[0]
        formState.id = data.id
        formState.titleFr = data.title.fr
        formState.titleAr = data.title.ar ? data.title.ar : ''
        formState.titleEn = data.title.en ? data.title.en : ''
        if (data.desc) {
          formState.descFr = data.desc.fr ? data.desc.fr : ''
          formState.descEn = data.desc.en ? data.desc.en : ''
          formState.descAr = data.desc.ar ? data.desc.ar : ''
        }
        formState.category = data.category._id
      })
      .catch(e => {
        message.error('impossible de trouver la publication')
        router.go(-1)
      })
    //defining consts
    const rules = {
      category: [
        {
          required: true,
          message: 'La catégorie est obligatoire',
          trigger: 'blur',
        },
      ],
    }
    const language = ['fr', 'ar', 'en']
    const sendToSubscribed = ref(false)
    const formRef = ref()
    const activeKey = ref('1')
    const files = ref([])
    const fileList = ref([])
    const covImgFileListFr = ref([])
    const covImgFileListAr = ref([])
    const covImgFileListEn = ref([])

    //  defining functions
    const deletetEmpty = obj => {
      for (const key of Object.keys(obj)) if (obj[key] == '') delete obj[key]
      return obj
    }
    const sendToSubscribedCallBack = () => {
      console.log('send to subscribed')
    }
    const onSubmit = () => {
      console.log(toRaw(formState))
      formRef.value
        .validate()
        .then(() => {
          let tempData = toRaw(formState)
          let data = {
            title: {
              fr: tempData.titleFr,
              en: tempData.titleEn,
              ar: tempData.titleAr,
            },
            desc: {
              fr: tempData.descFr,
              en: tempData.descEn,
              ar: tempData.descAr,
            },
            category: tempData.category,
            id: tempData.id,
          }
          data.title = deletetEmpty(data.title)
          data.desc = deletetEmpty(data.desc)
          data.id = deletetEmpty(data.id)
          ApiClient.patch('/publications/' + _id, {
            data,
          })
            .then(res => {
              let formData = new FormData()
              let imageChanged = 0
              console.log('file ', covImgFileListFr.value[0])
              if (covImgFileListFr.value[0]) {
                imageChanged += 1
                formData.append('coverImgFr', covImgFileListFr.value[0], res.data._id)
              }
              if (covImgFileListAr.value[0]) {
                imageChanged += 1

                formData.append('coverImgAr', covImgFileListAr.value[0], res.data._id)
              }
              if (covImgFileListEn.value[0]) {
                imageChanged += 1

                formData.append('coverImgEn', covImgFileListEn.value[0], res.data._id)
              }
              if (files.value.length > 0) {
                imageChanged += 1

                for (const file of files.value) formData.append('files[]', file, 'file')
              }
              console.log(imageChanged)
              if (imageChanged > 0) {
                console.log('666')

                ApiClient.patch('/publications/files/' + _id, formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                })
                  .catch(function() {
                    message.error("Impossible de télécharger l'image du coverture: ")
                  })
                  .finally(function() {
                    message.success('Publication Ajouter')
                  })
              }
            })
            .catch(() => message.error("Impossible d'ajouter la publication"))
            .finally(() => {
              if (sendToSubscribed.value) sendToSubscribedCallBack()
              router.push('/publications')
            })
        })
        .catch(e => console.debug(e))
    }
    const beforeUpload = (file, _) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        message.error('Vous ne pouvez télécharger que des fichiers JPG, JPEG ou PNG!')
      }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        message.error("L'image doit être inférieure à 5 Mo!")
      }
      if (isJpgOrPng && isLt2M) {
        message.success(`${file.name} fichier téléchargé avec succès..`)
        if (activeKey.value == 1) {
          covImgFileListFr.value = [file]
        } else if (activeKey.value == 2) {
          covImgFileListAr.value = [file]
        } else if (activeKey.value == 3) {
          covImgFileListEn.value = [file]
        }
      }
      return isJpgOrPng && isLt2M
    }
    const beforeUploadFiles = (file, _) => {
      files.value.push(file)
      return true
    }
    const handleFinishFailed = errors => {
      console.log(errors)
    }
    return {
      handleFinishFailed,
      sendToSubscribed,
      activeKey,
      language,
      files,
      fileList,
      covImgFileListFr,
      covImgFileListAr,
      covImgFileListEn,
      formState,
      categories,
      rules,
      formRef,
      onSubmit,
      beforeUpload,
      beforeUploadFiles,
    }
  },
})
</script>
